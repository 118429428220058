import React from "react";
import { Link } from "gatsby";
import { Image } from "../Image/Image";


const PromotedBlogPostItem = ({ item }) => {


    return (

        <Link to={item.url}>
            <div className="blogHeader__item">
                <div className="blogHeader__item-heading">
                    <p>{item.data.category}</p>
                    <h2>{item.data.short_title}</h2>
                    <h4>{item.data.title}</h4>
                </div>
                <div className="blogHeader__item-image">
                    <Image 
                    src={item.data.hero_image} 
                    loading={"eager"}
                    fadeIn={false}
                    />
                </div>
            </div>
        </Link>
    )
}

export default PromotedBlogPostItem