import React from "react";
import { Link } from "gatsby";
import BlogItemContent from "./BlogItemContent";

const BlogPostItem = ({ item }) => {

    const data = item.data

    return (
        <Link to={item.url}>
            <div className="blogItem">
                <BlogItemContent data={data} />
            </div>
        </Link>
    )
}

export default BlogPostItem