import React, { useState } from "react";
import SectionLayout from "../../layouts/SectionLayout";
import BlogPostItem from "./BlogPostItem";

const BlogPostsComponent = ({ data }) => {

    const [ moreBlogs, setMoreBlogs ] = useState(false)

    const contentHandler = () => {
        setMoreBlogs(!moreBlogs)
    }

    return (
        <SectionLayout sectionName="bk-color-white">
            {!moreBlogs &&
                <button
                    onClick={() => contentHandler()}
                    onKeyDown={() => contentHandler()} 
                    className="btn btn-primary p-2 showMoreBlogs "
                    >Show more blogs</button>
            }
            <div className={`blogposts-container ${(moreBlogs === true) ? ('show') : ('')}`}>
                {data.map(item => (
                    <BlogPostItem item={item}/>
                ))}
            </div>
        </SectionLayout>
    )
}

export default BlogPostsComponent