import React from "react";
import PromotedBlogPosts from "../../components/PromotedBlogPosts/PromotedBlogPosts";
import BlogPostsComponent from "../../components/BlogPostsComponent/BlogPostsComponent";

const BlogPageSection = ({ content }) => {

    const {
        promotedBlogPosts,
        blogPosts
    } = content

    return (
        <>
            <PromotedBlogPosts data={promotedBlogPosts}/>
            <BlogPostsComponent data={blogPosts}/>
        </>
    )
}


export default BlogPageSection