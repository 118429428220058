import React from "react";
import Footer from "../components/Footer/Footer";
import BlogHeader from "../components/BlogHeader/BlogHeader";

const BlogPageLayout = ({ children }) => {

    return (

        <div className="bk-color-lightwhite">
            <BlogHeader />
            <main>{children}</main>
            <Footer />
        </div>
    )
}

export default BlogPageLayout