import React, {useState} from "react";
import BodyClassName from "react-body-classname"
import NavContainer from "../Header/NavContainer";
import { useHeaderData } from "../StaticDataHooks/useHeaderData";

const BlogHeader = () => {
    const headerData = useHeaderData()

    const data = headerData
    const btn = data.btn_title
    const headerItems = data.header_data

    const [hamburgerActive, setHamburgerActive] = useState(false)

    const hamburgerHandler = () => {
        setHamburgerActive(!hamburgerActive)
    }

    let hamburgerClsName = "hamburger my-auto m-4"
    let navMenuClsName = "nav-items desktop lightmenu"
    let bodyClsName = ""
    
    if (hamburgerActive){
        hamburgerClsName += " active"
        navMenuClsName += " active"
        bodyClsName += "bk-color-white"
    }

    return (
        <BodyClassName className={bodyClsName}>
        <header className="header bk-color-lightwhite">
            <div className="layout-container">
                <NavContainer 
                    src={true}
                    hamburgerClsName={hamburgerClsName}
                    hamburgerHandler={hamburgerHandler}
                    spanClsn={"darkspan"}
                    navMenuClsName={navMenuClsName}
                    headerItems={headerItems}
                    btn={btn}
                    clsNme={'nav-link-white'}
                />
            </div>
        </header>
        </BodyClassName>
    )
}

export default BlogHeader