import React from "react";
import { Image } from "../Image/Image";

const BlogItemContent = ({ data }) => {


    return (
        <>
            <div>
                <Image 
                    className="blogItem__img" 
                    src={data.hero_image}
                    loading={"eager"}
                    fadeIn={false}
                />
            </div>
            <div className="blogItem__heading">
                <p>{data.category}</p>
                <h3>{data.short_title}</h3>
                <h6>{data.title}</h6>
            </div>
        </>
    )
}



export default BlogItemContent