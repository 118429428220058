import { graphql, useStaticQuery } from "gatsby";

export const useHeaderData = () => {
    const data = useStaticQuery(graphql`
        query HeaderDataQuery {
            prismicBlogheader {
                data {
                    btn_title
                        header_data {
                            link
                            name
                        }
                }
            }
        }
    `)

    const headerData = data.prismicBlogheader.data
    return headerData
}